<template>
    <div class="form-page">
        <div class="flex-center" style="position: fixed; right: 10px;top:20px;z-index: 2000">
            <div style="line-height: 1;font-size:0" @click="openService">
                <div class="christ-a">
                    <img src="../assets/pictures/mykefu.png" style="width: 30px;height: 30px;margin-right: 10px">
                </div>
              </div>
            <router-link class="btn lang-btn" to="/lang" v-if="select_lang > 0">
                <span class="iconfont icon-yuyan"></span>
                <span>{{$t('选择语言')}}</span>
            </router-link>
        </div>
        <div class="login-head">
            <div class="t1">{{$t('登录')}}</div>
            <van-popover v-model="showPopover" trigger="click">
                <div class="filter-dropdown">
                    <van-radio-group v-model="radio" >
                        <van-cell-group>
                            <van-cell :title="$t('邮箱')" clickable @click="changeRadio('1')" v-if="mail_register == 1">
                                <template #right-icon>
                                    <van-radio name="1" checked-color="#52cbeb" />
                                </template>
                            </van-cell>
                            <van-cell :title="$t('手机号')" clickable @click="changeRadio('2')">
                                <template #right-icon>
                                    <van-radio name="2" checked-color="#52cbeb"/>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
                <template #reference>
                    <div class="l-button">
                        <span>{{labelV}}</span><span class="iconfont icon-tree-node"></span>
                    </div>
                </template>
            </van-popover>
        </div>
        <div class="login-panel">
            <van-form class="login-form" @submit="handleClickLogin">
                <van-field v-if="radio == '2'" v-model="username" center :placeholder="$t('请输入你的手机号码')" type="digit">
                    <template #label>
                        <div class="sw-label" @click="showPicker = true">
                            <span class="lan-n">{{defaultCountry.value}}</span>
                            <span class="iconfont icon-tree-node"></span>
                        </div>
                    </template>
                </van-field>
                <van-field v-else v-model="username" :placeholder="$t('请输入Email')">
                </van-field>
                <van-field v-model="password" center :placeholder="$t('请输入密码')" v-if="sWpwd">
                    <template #button>
                        <van-icon name="eye" size="20px" color="#52cbeb" @click.native="sWpwd = false"/>
                    </template>
                </van-field>
                <van-field type="password" v-model="password" center :placeholder="$t('请输入密码')" v-else>
                    <template #button>
                        <van-icon name="closed-eye" size="20px" color="#52cbeb" @click.native="sWpwd = true"/>
                    </template>
                </van-field>
                <van-field v-if="gcode_show" v-model="gcode" center :placeholder="$t('请输入谷歌验证码')">
                </van-field>
                <div class="van-foot">
                    <router-link class="text-blue" to="/find-pwd">{{$t('忘记密码?')}}</router-link>
                </div>
            </van-form>
            <div class="form-foot-button">
                <van-button @click="handleClickLogin" type="primary" block>{{$t('登录')}}</van-button>
            </div>
            <div class="form-item"> {{$t('没有账户?')}} <router-link class="text-blue" to="/regist"> {{$t('注册')}}</router-link></div>
        </div>
        <div class="version">
            {{$t('版本号')}}
        </div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="allCountries"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                    value-key="text"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            >
            </van-picker>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                gcode_show: false,
                gcode: '',
                show: false,
                username: '',
                password: '',
                radio: '2',
                labelV: this.$t('手机号'),
                sWpwd: false,
                showPopover: false,
                showPicker:false,
                select_lang: false,
                defaultCountry: '+92',
                allCountries: [
                    {text: 'Pakistan (‫پاکستان‬‎) +92', value: '+92'},
                    {text: 'India (भारत) +91', value: '+91'},
                    {text: 'Bangladesh (বাংলাদেশ) +880', value: '+880'},
                    {text: 'United States +1', value: '+1'},
                    {text: 'Australia +61', value: '+61'},
                    {text: 'Singapore +65', value: '+65'},
                    {text: 'United Kingdom +44', value: '+44'},
                    {text: 'Japan +81', value: '+81'},
                    {text: 'South Korea (대한민국) +82', value: '+82'},
                    {text: 'Saudi Arabia (‫المملكة العربية السعودية‬‎) +966', value: '+966'},
                    {text: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎) +971', value: '+971'},
                ],
                mail_captcha: 0,
                mail_register: 0,
            }
        },
        methods: {
            changeRadio(val){
                if(val == '1'){
                    this.labelV = this.$t('邮箱');
                }else{
                    this.labelV = this.$t('手机号');
                }
                this.radio = val;
                this.username = '';
                this.showPopover = false
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            onConfirm(value) {
                this.showPicker = false;
                this.defaultCountry = value;
            },
            handleClickLogin() {
                if (this.username != "" && this.password != "") {
                    // if (this.username.indexOf("@") == -1 && this.radio == '1') {
                    //     this.$toast.fail(this.$t('邮箱格式不正确'));
                    //     return
                    // }
                    this.doLogin(this.username, this.password);
                } else {
                    this.$toast.fail(this.$t('请输入完整信息!'));
                }
            },
            openService(){
                window._vue.openService();
            },
            async getConfig() {
                await this.$axios.get(this.$api_url.get_config).then(res => {
                    if (res.data.code == 0) {
                        var data = res.data.data;
                        for (const key in data) {
                            localStorage.setItem(key, data[key]);
                        }
                        window._vue.$emit('getWhs');
                    }
                });
            },
            doLogin(username, password) {
                let loading = this.$toast.loading();
                let param = {
                    username: username,
                    password: password,
                    gcode: this.gcode,
                };
                if (this.radio == '2') {
                    param['country'] = this.defaultCountry;
                }
                this.$axios
                    .post(
                        this.$api_url.login,
                        this.$qs.stringify(param)
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            if(res.data.data.gcode_auth == 1){
                                this.gcode_show = true;
                                return ;
                            }
                            if (this.checked) {
                                localStorage.setItem('remember_username', username);
                            } else {
                                localStorage.setItem('remember_username', '');
                            }
                            this.$toast.success(res.data.msg);
                            this.$helper.setToken(res.data.data);
                            this.getConfig();
                            location.href = "/#/";
                        } else {
                            this.$toast.fail(this.$t(res.data.msg));
                        }
                    })
                    .catch((ex) => {
                        this.$toast.fail(this.$t(ex.data.msg));
                    });
            },
            getCountryCode(){
                this.$axios
                    .get('/country_code?type=login')
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.allCountries = res.data.data.list;
                            this.defaultCountry = res.data.data.default;
                        }
                    });
            }
        },
        mounted() {
            this.select_lang = localStorage.getItem('app.select_lang') || false;
            this.mail_register = localStorage.getItem('app.mail_register') || 0;
            this.mail_captcha = localStorage.getItem('app.mail_captcha') || 0;
        },
        created() {
            this.getCountryCode();
        }

    }
</script>
<style>
    .lang-class{
        display: inline-flex;
        align-items: center;
        height: 35px;
        padding: 0 12px;
        border: 1px solid #0184fa;
        font-size: 14px;
        color: #ffffff;
        border-radius: 10px;
        font-weight: bold;
        background-color: rgba(1, 132, 250, 0.25);
    }
</style>